<template>
  <div class="assinatura">
    <Voltar />
    <h1 class="titulo">Seu cadastro foi realizado com sucesso!</h1>
    <p>
      Agora, para participar de enquetes exclusivas, assistir ao vivo a
      programação da RedeTV! de qualquer local do mundo e concorrer aos prêmios
      diários, você precisa se tornar um assinante Rede America Prêmios.
    </p>
    <div class="bt bt--destaque">
      <a @click="() => router.push({ name: 'FormaPagto' })">Eu quero ser assinante</a>
    </div>
    <p class="margen-top">(Apenas R$ 5,00 por semana)</p>
    <p>Você cancela quando quiser, só com 3 clicks</p>
    <h3>Conheça as vantagens dos assinantes Rede America Prêmios</h3>
    <h6>Comande nossos programas</h6>
    <p>
      Palpite, opine e interfira nos programas de seus artistas preferidos
      através de enquetes exclusivas.
    </p>
    <h6>Prêmios todos os dias</h6>
    <p>
      Participe dos sorteios diários, e concorra a vários prêmios como Carros,
      Smartphones, Smart TVs, Barras em Ouro e muito mais, veja a promoção
      vigente.
    </p>
    <img :src="state.promocao.imagem" :alt="state.promocao.titulo" />
    <div class="bt">
      <a @click="() => router.push({ name: 'Regulamento' })">Regulamento</a>
    </div>
    <div class="bt bt--discreto">
      <a @click="() => router.push({ name: 'Home' })">Decidir isso depois</a>
    </div>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import services from '../../services'
export default {
  setup () {
    const router = useRouter()
    const toast = useToast()

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      promocao: {}
    })

    async function getPromocao () {
      try {
        state.isLoading = true
        const { data, errors } = await services.promocao.index()

        if (!errors) {
          state.promocao = data
          state.isLoading = false
          return
        }

        if (errors.status === 404 || errors.status === 401) {
          toast.error('Promoção não encontrada')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar a Promoção!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getPromocao()

    return {
      router,
      state
    }
  }
}
</script>
